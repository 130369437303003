.productList .ant-list-header{
  border-bottom: none !important;
}

.cardInfo {
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 20px;
  zoom: 1;
}

.cardInfo:before,
.cardInfo:after {
  display: table;
  content: ' ';
}

.cardInfo:after {
  clear: both;
  height: 0;
  font-size: 0;
  visibility: hidden;
}

.cardInfo div {
  position: relative;
  float: left;
  width: 50%;
  text-align: left;
}

.cardInfo p {
  margin: 0;
  font-size: 24px;
  line-height: 32px;
}

.cardInfo p:first-child {
  margin-bottom: 4px;
  color: '#000';
  font-size: 20px;
  line-height: 20px;
}
